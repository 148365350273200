//require("./index.scss");
require("./web/css/main.css");
require("./web/js/main.js");

import * as dompack from 'dompack';

import "@webhare-system/wh/integration"; //we're a WebHare site
import "@webhare-publisher/richcontent/all";

//At minimum, activates required CSS and JSON/RPC links
import * as forms from '@mod-publisher/js/forms';

import { RPCFormBase, registerHandler } from "@mod-publisher/js/forms";

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__uploadfield", node => new UploadField(node));

//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node, { hidebuttons: ['table'] }));

forms.setup({ validate: true });

//require('./index.scss');
//alert("hello2");
function cleanupEmptyParagraphs(pnode)
{//cleanup empty paragraphs between fullwidth blocks
  for(let node of pnode.querySelectorAll(".contents--fullwidth"))
  {
    let prevnode = node.previousElementSibling;
    if(prevnode && prevnode.nodeName == "P" && (prevnode.innerHTML == "" || prevnode.innerHTML == "<br>") )
      node.parentNode.removeChild(prevnode);
  }
}

dompack.register("article", node => cleanupEmptyParagraphs(node) );

document.addEventListener("DOMContentLoaded",function()
{
  //alert("DOMContentLoaded");
}); //DOMContentLoaded


class RTDForm extends RPCFormBase
{
  constructor(node)
  {
    super(node);
    //node.querySelector(".prefillbutton").addEventListener("click", () => this.doPrefill());
    this.doPrefill();
  }
  //onSubmit(_data)
  //{
  //  console.log(_data);
  //  this.submit();
  //}
  onSubmitFailed(_errors, _result)
  {
    //console.log(_errors); // string array
    //console.log(_result);
  }
  onSubmitSuccess(result)
  {
    //console.log(JSON.stringify(result));
    //qS("#rtdformresponse").textContent = JSON.stringify(result);
  }
  async doPrefill()
  {
    //console.log("doPrefill");
    let prefilled = await this.invokeRPC("PrefillRTD");
    //console.log(prefilled);
    //this.elements.rtdpersonprofile.value = prefilled.personprofile;
    //dompack.qS("#rtdPersonProfile").textContent = prefilled.personprofile;
    //console.log(this.elements.rtdPersonProfile);
  }
}

registerHandler("clubcontext:rtdform", node => new RTDForm(node));
//PersonProfileForm.invokeRPC('prefillrtd');
//let form = dompack.qS("#rtdform");
//form.doPrefill();
